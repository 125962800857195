/* entry-content */
[lang='th'] {
  .entry-content {
    font-family:
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
  }

  .entry-content {
    *:not(input):not(.button):not(i) {
      font-family:
        var(--font-sarabun),
        BlinkMacSystemFont,
        -apple-system,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        sans-serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h1 *,
    h2 *,
    h3 *,
    h4 *,
    h5 *,
    h6 * {
      font-family: var(--font-aktiv), var(--font-satoshiRealBold),
        var(--font-satoshi), sans-serif !important;
    }
  }
}

.entry-content {
  line-height: 210%;
  font-size: 1.04rem;
  * {
    @apply tw-leading-[190%];
    font-family:
      var(--font-satoshi),
      var(--font-aktiv),
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
  }

  /* p,
  div,
  ol,
  ul {
    @apply tw-min-h-[1rem];
  } */
  strong {
    @apply tw-font-bold;
  }
  iframe {
    @apply tw-w-full;
  }

  iframe {
    min-width: 100% !important;
    max-width: 100% !important;
    &[src*='youtube'],
    &[src*='youtu.be'] {
      height: 52.5vw;
      @screen md {
        height: 400px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h1 *,
  h2 *,
  h3 *,
  h4 *,
  h5 *,
  h6 * {
    font-family: var(--font-satoshiRealBold), var(--font-satoshi), sans-serif;
    font-weight: 700;
    line-height: 125%;
    /* heading ไม่ควรติดกับ element ถัดลงมาจนเกินไป */
    margin-bottom: 0.5rem;
    @apply tw-text-bgh-blue;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + ul,
    + ol {
      /* ทำให้ UL และ OL ที่อยู่ติดกับ heading ไม่ดูห่างจนเกินไป */
      padding-top: 0;
      margin-top: -0.2rem;
    }
  }

  p {
    line-height: 210% !important;
    margin-bottom: 16px;
    font-size: 1.04rem;
    background: transparent !important;
  }

  /* img,
  iframe {
    @apply tw-mb-4;
  } */
  blockquote {
    @apply tw-relative tw-px-12 tw-py-6 tw-my-8;
    background: linear-gradient(
      257.63deg,
      #f2f2f2 -16.09%,
      hsla(0, 0%, 94.9%, 0) 145.86%
    );
  }

  blockquote::before {
    @apply tw-absolute  tw-top-0 tw-left-0 tw-w-full tw-h-full tw-content-[''];
    background-image: url('https://static.bangkokhospital.com/uploads/2020/04/block.svg');
    background-repeat: no-repeat;
  }

  img {
    @apply tw-rounded;
  }
  img.size-full {
    @apply tw-w-full tw-h-auto;
  }

  /* Unordered list */
  & ul {
    list-style: none !important;
    margin: 8px 0;
    background: transparent !important;

    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      padding-top: 24px;
    }
  }

  & > ul,
  & > ol > li > ul {
    list-style: none !important;
    position: relative;

    & > li {
      border-bottom: 1px dotted rgba(0, 0, 0, 0.08);
      padding-left: 24px;

      &:last-child {
        border-bottom: none;
      }

      padding-bottom: 0.5em;
      padding-top: 0.5em;
      position: relative;

      &::before {
        @apply tw-bg-bgh-blue;
        content: '';
        position: absolute;
        left: 5px;
        top: 22px;
        height: 6px;
        width: 6px;
        border-radius: 3px;
      }
    }
  }

  /* Ordered list */
  ol {
    @apply ltr:tw-ml-6 rtl:tw-mr-6;
    margin-top: 8px;
    margin-bottom: 8px;
    background: transparent !important;
    list-style: decimal;
  }

  li {
    p {
      display: inline;
    }

    h3 {
      padding-top: 0;
    }
  }

  .aligncenter {
    margin: 0 auto;
  }

  .gallery-item {
    a {
      pointer-events: none;
      cursor: default;
    }

    @media (max-width: theme('screens.sm')) {
      width: 100% !important;
      margin-bottom: 12px;

      img {
        padding: 0px;
      }
    }
  }

  & > ul {
    & ul {
      h3,
      h4 {
        padding: 4px 0;

        &::after {
          margin-bottom: 12px;
        }
      }

      & > li {
        position: relative;

        &::before {
          @apply tw-bg-bgh-blue tw-border-bgh-blue;
          content: '';
          position: absolute;
          left: 5px;
          top: 13px;
          height: 6px;
          width: 6px;
          border-radius: 3px;
        }

        padding-left: 24px;

        & > ul {
          & > li {
            position: relative;

            &::before {
              @apply tw-bg-primary;
              content: '';
              position: absolute;
              left: 0px;
              top: 15px;
              height: 1px;
              width: 17px;
            }
          }
        }
      }
    }
  }

  .gallery {
    margin-bottom: 16px !important;

    .gallery-item {
      a {
        pointer-events: none !important;
      }
    }
  }

  /* Table */
  table {
    @apply tw-shadow-main-blue;
    margin-top: 24px;
    margin-bottom: 24px;
    border: 0 !important;
    display: table;
    border-radius: 3px;
    overflow-x: auto;
    max-width: 100%;
    height: auto !important;
    @media (max-width: theme('screens.md')) {
      tbody,
      th {
        width: 100%;
        /* display: table-cell; */
        background: white;
      }
      display: block;
    }
    th {
      border: 0 !important;
      text-align: center;
      padding: 1vw;
      vertical-align: middle;
      @media (max-width: theme('screens.sm')) {
        min-width: 150px;
      }
    }
    td {
      @apply tw-border-bgh-blue-alpha tw-border !tw-border-b !tw-border-b-bgh-blue-alpha rtl:tw-text-left;
      margin: 24px 0px;
      padding: 1vw;
      width: auto !important;
      @media (max-width: theme('screens.sm')) {
        min-width: 150px;
      }
    }

    tr {
      transition: all 0.2s var(--bezier);
      &:hover {
        @apply tw-bg-bgh-blue-alpha/40 tw-text-bgh-blue;
      }
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img {
      padding: 0 !important;
      margin: 0 !important;
    }

    @media (max-width: theme('screens.sm')) {
      h1 {
        font-size: 6vw !important;
      }

      h2 {
        font-size: 5.5vw !important;
      }

      h3 {
        font-size: 5vw !important;
      }

      h4 {
        font-size: 4.5vw !important;
      }

      h5 {
        font-size: 4vw !important;
      }

      h6 {
        font-size: 3.5vw !important;
      }
    }

    li {
      @apply !tw-p-0 !tw-m-0 before:tw-hidden;
    }
  }
  &.package-promotion {
    table {
      @media (max-width: theme('screens.md')) {
        tbody,
        th {
          display: table-cell;
          max-width: 100% !important;
          width: 100% !important;
          table-layout: fixed;

          td {
            white-space: normal;
            padding: 2vw;
          }
        }
        width: 100% !important;
        hyphens: auto;
        table-layout: fixed;
        display: table;
      }
    }
  }

  /* Styling */
  h3,
  > h3 {
    @apply tw-text-bgh-blue;
    font-size: 1.35rem;
    display: inline !important;
    background-image: linear-gradient(
      transparent calc(100% - 10px),
      rgba(0, 100, 255, 0.1) 10px
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: background-size 1s;
    text-transform: capitalize;
  }
  h3:after {
    content: '';
    display: block;
    height: 0;
    margin-bottom: 16px;
  }
  h4 {
    @apply tw-text-bgh-blue;
    line-height: 125% !important;
    margin-bottom: 20px;
  }

  h1 + ol,
  h1 + ul,
  h2 + ol,
  h2 + ul,
  h3 + ol,
  h3 + ul,
  h4 + ol,
  h4 + ul,
  h5 + ol,
  h5 + ul,
  h6 + ol,
  h6 + ul {
    margin-top: -12px;
  }
  ol > li > ul,
  ul {
    list-style: none !important;
    position: relative;
    margin: 8px 0;
    background: transparent !important;
  }
  ol > li > ul > li,
  ul > li {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.08);
    @apply tw-relative tw-pt-[0.5em] tw-pb-[0.5em] ltr:tw-pl-6 rtl:tw-pr-6;
  }
  img.size-full {
    width: 100%;
  }
  ol > li > ul > li:before,
  ul > li:before {
    @apply tw-bg-bgh-blue ltr:!tw-left-[5px] ltr:!tw-right-auto rtl:tw-right-[5px] rtl:tw-left-auto;
    content: '';
    position: absolute;
    top: 22px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
  }

  p + ol,
  p + ul {
    margin-top: -12px;
  }
  hr {
    outline: none;
    border: none;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.04);
    display: block;
    margin: 1.5rem 0;
  }
  ol > li > ul > li:last-child,
  ul > li:last-child {
    border-bottom: none;
  }
  a.-tag {
    @apply tw-rounded-full tw-text-sm tw-py-1.5  tw-px-5 sm:tw-py-1.5 tw-bg-bgh-blue-alpha !tw-text-bgh-blue !tw-font-bold !tw-whitespace-normal !tw-h-full hover:!tw-bg-bgh-blue/20;
  }
  a {
    @apply tw-text-primary;
    transition: 0.3s;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    cursor: pointer;
  }

  /* hightlight */
  .-highlight {
    background-image: linear-gradient(
      transparent calc(100% - 10px),
      #e7edff 10px
    );
    @apply tw-block tw-text-primary tw-capitalize tw-w-fit tw-h-8;
  }

  h4.-highlight {
    font-size: 1.5rem !important;
    margin-bottom: 4px !important;
  }

  /* input */
  input {
    @apply tw-border tw-w-full tw-border-bgh-blue-light tw-px-2 tw-py-1 tw-rounded;
  }
  input:disabled {
    color: #292929 !important;
    border-color: transparent !important;
    background-color: rgba(0, 0, 0, 0.025) !important;
    -webkit-text-fill-color: #292929 !important;
    opacity: 1 !important;
  }
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    @apply !tw-bg-primary tw-border-primary tw-text-white tw-cursor-pointer;
  }
  .button {
    @apply tw-bg-bgh-blue tw-border-bgh-blue-light tw-border tw-overflow-hidden tw-inline-flex tw-align-top;
    padding: 20px;
    margin: 3px 1px;
    font-weight: 700 !important;
    outline: none !important;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    white-space: normal;
    overflow: hidden;
    height: auto;
    min-width: 140px;
    transition: 0.2s var(--bezier);
    color: white;
    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        -34.83deg,
        rgba(216, 231, 255, 0.2) 17.64%,
        rgba(168, 202, 255, 0.05) 71.04%
      );
      top: 0;
      left: -15%;
      width: 20%;
      height: 100%;
      transform: skew(-45deg);
      transition: all 0.3s var(--bezier);
    }

    &::after {
      position: absolute;
      content: '';
      background: linear-gradient(
        34.83deg,
        rgba(216, 231, 255, 0.12) 17.64%,
        rgba(168, 202, 255, 0.05) 71.04%
      );
      top: 0;
      right: -15%;
      width: 20%;
      height: 100%;
      transform: skew(-45deg);
      transition: all 0.3s var(--bezier);
    }
    &:not(.-disabled):hover {
      @apply tw-bg-primary;
      transform: scale(1.01);
      border-color: transparent;

      &::before {
        left: -50%;
        top: -50%;
      }

      &::after {
        right: -50%;
        top: 50%;
      }
    }
    @media (min-width: theme('screens.md')) {
      min-width: 180px;
    }
    @media (max-width: theme('screens.md')) {
      max-width: 100%;
      min-width: 25vw;
      padding: 14px 12px;
      font-size: 0.95rem;
    }
    @media (max-width: 320px) {
      max-width: 100%;
      min-width: 40vw;
      padding: 14px 18px;
    }
  }
}
