/* Insert any special effect that every project can use here! */

/* Ripple Link effect */
.ripple-link {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  cursor: pointer;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(125, 129, 180, 0.1);
}

.-dark span.ripple {
  background-color: rgba(225, 229, 230, 0.5);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes blue-pulse {
  0% {
    border-color: rgba(0, 20, 150, 0.4);
    border-width: 10px;
    border-style: solid;
    transform: scale(0.8);
    transform-origin: center;
  }
  100% {
    border-color: rgba(0, 0, 255, 0);
    border-width: 1px;
    border-style: solid;
    transform: scale(1.3);
    transform-origin: center;
  }
}

@keyframes blue-pulse-small {
  0% {
    border-color: rgba(0, 20, 150, 0.4);

    border-style: solid;
    transform: scale(0.9);
    transform-origin: center;
  }
  100% {
    border-color: rgba(0, 0, 255, 0);

    border-style: solid;
    transform: scale(1.1);
    transform-origin: center;
  }
}

@keyframes blue-bg {
  0% {
    background: #0147a3;
  }
  20% {
    background: #0053bf;
  }
  50% {
    background: #0147a3;
  }
  100% {
    background: #0147a3;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.animated-gradient-blue {
  background: linear-gradient(90deg, #0147a3, #0053bf);
  background-size: 400% 400%;
  animation: blue-bg 1s infinite;
}
