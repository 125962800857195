/* thai */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a55e302a1fadf946-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f5df9bb82b39b1d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c566a68d4ef270f0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f876dfe2e8ed0b7a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a0699cace6f22665-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1b86bbb0e0aba1f4-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a8cee68ffb1b6564-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/64a52b4e53bd2712-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ab03b37c15855205-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a7610dba1678ee1d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8158c7bae1960491-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_4d221e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/daacbd8d426571ce-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sarabun_Fallback_4d221e';src: local("Arial");ascent-override: 109.45%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 97.58%
}.__className_4d221e {font-family: '__Sarabun_4d221e', '__Sarabun_Fallback_4d221e';font-style: normal
}.__variable_4d221e {--font-sarabun: '__Sarabun_4d221e', '__Sarabun_Fallback_4d221e'
}

@font-face {
font-family: '__satoshi_4443ec';
src: url(/_next/static/media/d9396795aa5ec363-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400 900;
font-style: normal;
}@font-face {font-family: '__satoshi_Fallback_4443ec';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_4443ec {font-family: '__satoshi_4443ec', '__satoshi_Fallback_4443ec';font-style: normal
}.__variable_4443ec {--font-satoshi: '__satoshi_4443ec', '__satoshi_Fallback_4443ec'
}

/* latin-ext */
@font-face {
  font-family: '__Figtree_349095';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/993cbd19130fcf51-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_349095';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8dfe6d40ab27bf9a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_349095';src: local("Arial");ascent-override: 94.32%;descent-override: 24.82%;line-gap-override: 0.00%;size-adjust: 100.72%
}.__className_349095 {font-family: '__Figtree_349095', '__Figtree_Fallback_349095';font-weight: 700;font-style: normal
}.__variable_349095 {--font-satoshiRealBold: '__Figtree_349095', '__Figtree_Fallback_349095'
}

/* Insert any special effect that every project can use here! */

/* Ripple Link effect */
.ripple-link {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  cursor: pointer;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(125, 129, 180, 0.1);
}

.-dark span.ripple {
  background-color: rgba(225, 229, 230, 0.5);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes blue-pulse {
  0% {
    border-color: rgba(0, 20, 150, 0.4);
    border-width: 10px;
    border-style: solid;
    transform: scale(0.8);
    transform-origin: center;
  }
  100% {
    border-color: rgba(0, 0, 255, 0);
    border-width: 1px;
    border-style: solid;
    transform: scale(1.3);
    transform-origin: center;
  }
}

@keyframes blue-pulse-small {
  0% {
    border-color: rgba(0, 20, 150, 0.4);

    border-style: solid;
    transform: scale(0.9);
    transform-origin: center;
  }
  100% {
    border-color: rgba(0, 0, 255, 0);

    border-style: solid;
    transform: scale(1.1);
    transform-origin: center;
  }
}

@keyframes blue-bg {
  0% {
    background: #0147a3;
  }
  20% {
    background: #0053bf;
  }
  50% {
    background: #0147a3;
  }
  100% {
    background: #0147a3;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.animated-gradient-blue {
  background: linear-gradient(90deg, #0147a3, #0053bf);
  background-size: 400% 400%;
  animation: blue-bg 1s infinite;
}

.swiper-container {
  height: 100%;
  /* max-height: 100vw; */
  min-height: 0;
  min-width: 0;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  display: block;
  height: auto !important;
  max-height: 100%;
}

.swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;

  /* padding for pagination */
}

/* .swiper-pagination-bullets {
  @apply !tw-bottom-2.5;
} */

.swiper-pagination-bullet {
  border-radius: 5px !important;
  width: 5px !important;
  height: 5px !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
  transition-duration: 300ms;
}
.swiper-pagination-bullet-active {
  width: 24px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transition-duration: 300ms;
  font-size: 28px !important;
  display: flex;
  justify-content: center;
}

.swiper-button-next:after {
  left: 2px;
  position: relative;
}

.swiper-button-prev:after {
  right: 2px;
  position: relative;
}

.swiper-button-disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.swiper-button-disabled:after {
  color: rgb(0 0 0 / 0.2) !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: 0 !important;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateY(20px);
  transition: all 0.2s ease-in-out !important;
  top: 45% !important;
  /* move pagination up a little bit for balancing with pagination */
}

/* Some navigation might overlap card title when hover */
/* Ex: HighlightPosts */
.offset-navigation .swiper-button-next,
.offset-navigation .swiper-button-prev {
  top: 20% !important;
}

.hero-slide-with-backdrop .swiper-button-next,
.hero-slide-with-backdrop .swiper-button-prev {
  top: 38% !important;
}

.hero-slide-with-backdrop .swiper-button-prev {
  left: 1.5rem;
}
.hero-slide-with-backdrop .swiper-button-next {
  right: 0.5rem;
}

.backdrop-panel {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: -10;
  height: 100%;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 300ms;
  transition-duration: 500ms;
}

.swiper-slide-active .backdrop-panel {
  right: 0.75rem;
  bottom: 0.75rem;
  border-radius: 0.25rem;
}

@media (min-width: 1080px) {

  .swiper-slide-active .backdrop-panel {
    right: 1rem;
    bottom: 1rem;
  }
}

.swiper-button-next,
.swiper-button-prev {
  --tw-backdrop-blur: blur(4px);
  --tw-backdrop-brightness: brightness(.75);
  --tw-backdrop-contrast: contrast(.75);
  --tw-backdrop-opacity: opacity(0.5);
  --tw-backdrop-saturate: saturate(1.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  transform: translateY(0px);
  opacity: 1 !important;
  transition: all 0.2s ease-in-out !important;
}

.swiper-button-next[aria-disabled='true'],
.swiper-button-prev[aria-disabled='true'] {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.inverted .swiper-button-next,
.inverted .swiper-button-prev {
  background-color: rgb(0 0 0 / 0.05);
}

.inverted .swiper-button-next:after,
.inverted .swiper-button-prev:after {
  color: rgb(255 255 255 / 0.8);
}

.inverted .swiper-button-next[aria-disabled='true'],
.inverted .swiper-button-prev[aria-disabled='true'] {
  opacity: 0.2 !important;
}

.remove-padding .swiper-wrapper {
  padding: 0px !important;
}

.left-pagination .swiper-pagination {
  text-align: left !important;
}

@media (max-width: 1079px) {
  .center-pagination-mobile .swiper-pagination {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .center-pagination-desktop .swiper-pagination {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
}

.pagination-x-padding .swiper-pagination {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* has side effect on other page. Don't force it for every page */
/* .swiper-pagination {
  @apply md:!tw-bottom-2.5 !-tw-bottom-2.5;
} */

@media (max-width: 1079px) {
  .overflow-visible-mobile {
    overflow: visible !important;
  }
}

/* Add  watchSlidesProgress: true to swiper options when using swiper to hide overflow slide */
.watch-slide .swiper-slide {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s !important;
}
.watch-slide .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s !important;
}

.has-divider {
  position: relative;
}

.has-divider::after {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -19px;
  height: 100%;
  width: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
    content: '';
}

/* entry-content */
[lang='th'] .entry-content {
    font-family:
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
  }
[lang='th'] .entry-content *:not(input):not(.button):not(i) {
      font-family:
        var(--font-sarabun),
        BlinkMacSystemFont,
        -apple-system,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        sans-serif;
    }
[lang='th'] .entry-content h1,
    [lang='th'] .entry-content h2,
    [lang='th'] .entry-content h3,
    [lang='th'] .entry-content h4,
    [lang='th'] .entry-content h5,
    [lang='th'] .entry-content h6,
    [lang='th'] .entry-content h1 *,
    [lang='th'] .entry-content h2 *,
    [lang='th'] .entry-content h3 *,
    [lang='th'] .entry-content h4 *,
    [lang='th'] .entry-content h5 *,
    [lang='th'] .entry-content h6 * {
      font-family: var(--font-aktiv), var(--font-satoshiRealBold),
        var(--font-satoshi), sans-serif !important;
    }

.entry-content {
  line-height: 210%;
  font-size: 1.04rem;
}

.entry-content * {
    line-height:
      190%;
    font-family:
      var(--font-satoshi),
      var(--font-aktiv),
      var(--font-sarabun),
      BlinkMacSystemFont,
      -apple-system,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif;
}

/* p,
  div,
  ol,
  ul {
    @apply tw-min-h-[1rem];
  } */

.entry-content strong {
    font-weight:
      700;
}

.entry-content iframe {
    width:
      100%;
    min-width: 100% !important;
    max-width: 100% !important;
}

.entry-content iframe[src*='youtube'],
    .entry-content iframe[src*='youtu.be'] {
      height: 52.5vw;
    }

@media (min-width: 1080px) {

.entry-content iframe[src*='youtube'],
    .entry-content iframe[src*='youtu.be'] {
        height: 400px
    }
      }

.entry-content h1,
  .entry-content h2,
  .entry-content h3,
  .entry-content h4,
  .entry-content h5,
  .entry-content h6,
  .entry-content h1 *,
  .entry-content h2 *,
  .entry-content h3 *,
  .entry-content h4 *,
  .entry-content h5 *,
  .entry-content h6 * {
    font-family: var(--font-satoshiRealBold), var(--font-satoshi), sans-serif;
    font-weight: 700;
    line-height: 125%;
    /* heading ไม่ควรติดกับ element ถัดลงมาจนเกินไป */
    margin-bottom: 0.5rem;
    --tw-text-opacity:
      1;
    color:
      rgb(1 71 163 / var(--tw-text-opacity));
  }

.entry-content h2 {
    font-size: 1.8rem;
  }

.entry-content h3 {
    font-size: 1.5rem;
  }

.entry-content h4 {
    font-size: 1.3rem;
  }

.entry-content h1 + ul,
    .entry-content h1 + ol,
    .entry-content h2 + ul,
    .entry-content h2 + ol,
    .entry-content h3 + ul,
    .entry-content h3 + ol,
    .entry-content h4 + ul,
    .entry-content h4 + ol,
    .entry-content h5 + ul,
    .entry-content h5 + ol,
    .entry-content h6 + ul,
    .entry-content h6 + ol {
      /* ทำให้ UL และ OL ที่อยู่ติดกับ heading ไม่ดูห่างจนเกินไป */
      padding-top: 0;
      margin-top: -0.2rem;
    }

.entry-content p {
    line-height: 210% !important;
    margin-bottom: 16px;
    font-size: 1.04rem;
    background: transparent !important;
  }

/* img,
  iframe {
    @apply tw-mb-4;
  } */

.entry-content blockquote {
    position:
      relative;
    margin-top:
      2rem;
    margin-bottom:
      2rem;
    padding-left:
      3rem;
    padding-right:
      3rem;
    padding-top:
      1.5rem;
    padding-bottom:
      1.5rem;
    background: linear-gradient(
      257.63deg,
      #f2f2f2 -16.09%,
      hsla(0, 0%, 94.9%, 0) 145.86%
    );
}

.entry-content blockquote::before {
    position:
      absolute;
    top:
      0px;
    left:
      0px;
    height:
      100%;
    width:
      100%;
    --tw-content:
      '';
    content:
      var(--tw-content);
    background-image: url('https://static.bangkokhospital.com/uploads/2020/04/block.svg');
    background-repeat: no-repeat;
}

.entry-content img {
    border-radius:
      0.25rem;
}

.entry-content img.size-full {
    height:
      auto;
    width:
      100%;
}

/* Unordered list */

.entry-content ul {
    list-style: none !important;
    margin: 8px 0;
    background: transparent !important;
  }

.entry-content ul + h2,
    .entry-content ul + h3,
    .entry-content ul + h4,
    .entry-content ul + h5,
    .entry-content ul + h6 {
      padding-top: 24px;
    }

.entry-content > ul,
  .entry-content > ol > li > ul {
    list-style: none !important;
    position: relative;
  }

.entry-content > ul > li, .entry-content > ol > li > ul > li {
      border-bottom: 1px dotted rgba(0, 0, 0, 0.08);
      padding-left: 24px;
    }

.entry-content > ul > li:last-child, .entry-content > ol > li > ul > li:last-child {
        border-bottom: none;
      }

.entry-content > ul > li, .entry-content > ol > li > ul > li {

      padding-bottom: 0.5em;
      padding-top: 0.5em;
      position: relative;
}

.entry-content > ul > li::before, .entry-content > ol > li > ul > li::before {
    --tw-bg-opacity:
      1;
    background-color:
      rgb(1 71 163 / var(--tw-bg-opacity));
        content: '';
        position: absolute;
        left: 5px;
        top: 22px;
        height: 6px;
        width: 6px;
        border-radius: 3px;
}

/* Ordered list */

.entry-content ol:where([dir="ltr"], [dir="ltr"] *) {
    margin-left:
      1.5rem;
}

.entry-content ol:where([dir="rtl"], [dir="rtl"] *) {
    margin-right:
      1.5rem;
}

.entry-content ol {
    margin-top: 8px;
    margin-bottom: 8px;
    background: transparent !important;
    list-style: decimal;
  }

.entry-content li p {
      display: inline;
    }

.entry-content li h3 {
      padding-top: 0;
    }

.entry-content .aligncenter {
    margin: 0 auto;
  }

.entry-content .gallery-item a {
      pointer-events: none;
      cursor: default;
    }

@media (max-width: 768px) {

.entry-content .gallery-item {
      width: 100% !important;
      margin-bottom: 12px
  }

      .entry-content .gallery-item img {
        padding: 0px;
      }
    }

.entry-content > ul ul h3,
      .entry-content > ul ul h4 {
        padding: 4px 0;
      }

.entry-content > ul ul h3::after, .entry-content > ul ul h4::after {
          margin-bottom: 12px;
        }

.entry-content > ul ul > li {
        position: relative;
      }

.entry-content > ul ul > li::before {
    --tw-border-opacity:
      1;
    border-color:
      rgb(1 71 163 / var(--tw-border-opacity));
    --tw-bg-opacity:
      1;
    background-color:
      rgb(1 71 163 / var(--tw-bg-opacity));
          content: '';
          position: absolute;
          left: 5px;
          top: 13px;
          height: 6px;
          width: 6px;
          border-radius: 3px;
}

.entry-content > ul ul > li {

        padding-left: 24px;
}

.entry-content > ul ul > li > ul > li {
            position: relative;
          }

.entry-content > ul ul > li > ul > li::before {
    --tw-bg-opacity:
      1;
    background-color:
      rgb(0 45 115 / var(--tw-bg-opacity));
              content: '';
              position: absolute;
              left: 0px;
              top: 15px;
              height: 1px;
              width: 17px;
}

.entry-content .gallery {
    margin-bottom: 16px !important;
  }

.entry-content .gallery .gallery-item a {
        pointer-events: none !important;
      }

/* Table */

.entry-content table {
    --tw-shadow:
      0 8px 20px rgba(0, 102, 255, 0.12),0 3px 5px rgba(81, 126, 194, 0.1);
    --tw-shadow-colored:
      0 8px 20px var(--tw-shadow-color), 0 3px 5px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    margin-top: 24px;
    margin-bottom: 24px;
    border: 0 !important;
    display: table;
    border-radius: 3px;
    overflow-x: auto;
    max-width: 100%;
    height: auto !important;
}

@media (max-width: 1080px) {

.entry-content table {
      display: block
  }
      .entry-content table tbody,
      .entry-content table th {
        width: 100%;
        /* display: table-cell; */
        background: white;
      }
    }

.entry-content table th {
      border: 0 !important;
      text-align: center;
      padding: 1vw;
      vertical-align: middle;
    }

@media (max-width: 768px) {

.entry-content table th {
        min-width: 150px
    }
      }

.entry-content table td {
    border-width:
      1px;
    border-bottom-width:
      1px !important;
    border-color:
      rgb(231 237 255 / var(--tw-border-opacity));
    --tw-border-opacity:
      1 !important;
    border-bottom-color:
      rgb(231 237 255 / var(--tw-border-opacity)) !important;
}

.entry-content table td:where([dir="rtl"], [dir="rtl"] *) {
    text-align:
      left;
}

.entry-content table td {
      margin: 24px 0px;
      padding: 1vw;
      width: auto !important;
    }

@media (max-width: 768px) {

.entry-content table td {
        min-width: 150px
    }
      }

.entry-content table tr {
      transition: all 0.2s var(--bezier);
    }

.entry-content table tr:hover {
    background-color:
      rgb(231 237 255 / 0.4);
    --tw-text-opacity:
      1;
    color:
      rgb(1 71 163 / var(--tw-text-opacity));
}

.entry-content table h1,
    .entry-content table h2,
    .entry-content table h3,
    .entry-content table h4,
    .entry-content table h5,
    .entry-content table h6,
    .entry-content table img {
      padding: 0 !important;
      margin: 0 !important;
    }

@media (max-width: 768px) {
      .entry-content table h1 {
        font-size: 6vw !important;
      }

      .entry-content table h2 {
        font-size: 5.5vw !important;
      }

      .entry-content table h3 {
        font-size: 5vw !important;
      }

      .entry-content table h4 {
        font-size: 4.5vw !important;
      }

      .entry-content table h5 {
        font-size: 4vw !important;
      }

      .entry-content table h6 {
        font-size: 3.5vw !important;
      }
    }

.entry-content table li {
    margin:
      0px !important;
    padding:
      0px !important;
}

.entry-content table li::before {
    content:
      var(--tw-content);
    display:
      none;
}

@media (max-width: 1080px) {

.entry-content.package-promotion table {
        width: 100% !important;
        -webkit-hyphens: auto;
                hyphens: auto;
        table-layout: fixed;
        display: table
    }
        .entry-content.package-promotion table tbody,
        .entry-content.package-promotion table th {
          display: table-cell;
          max-width: 100% !important;
          width: 100% !important;
          table-layout: fixed;
        }

          .entry-content.package-promotion table tbody td, .entry-content.package-promotion table th td {
            white-space: normal;
            padding: 2vw;
          }
      }

/* Styling */

.entry-content h3,
  .entry-content > h3 {
    --tw-text-opacity:
      1;
    color:
      rgb(1 71 163 / var(--tw-text-opacity));
    font-size: 1.35rem;
    display: inline !important;
    background-image: linear-gradient(
      transparent calc(100% - 10px),
      rgba(0, 100, 255, 0.1) 10px
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: background-size 1s;
    text-transform: capitalize;
}

.entry-content h3:after {
    content: '';
    display: block;
    height: 0;
    margin-bottom: 16px;
  }

.entry-content h4 {
    --tw-text-opacity:
      1;
    color:
      rgb(1 71 163 / var(--tw-text-opacity));
    line-height: 125% !important;
    margin-bottom: 20px;
}

.entry-content h1 + ol,
  .entry-content h1 + ul,
  .entry-content h2 + ol,
  .entry-content h2 + ul,
  .entry-content h3 + ol,
  .entry-content h3 + ul,
  .entry-content h4 + ol,
  .entry-content h4 + ul,
  .entry-content h5 + ol,
  .entry-content h5 + ul,
  .entry-content h6 + ol,
  .entry-content h6 + ul {
    margin-top: -12px;
  }

.entry-content ol > li > ul,
  .entry-content ul {
    list-style: none !important;
    position: relative;
    margin: 8px 0;
    background: transparent !important;
  }

.entry-content ol > li > ul > li,
  .entry-content ul > li {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.08);
    position:
      relative;
    padding-top:
      0.5em;
    padding-bottom:
      0.5em;
  }

.entry-content ol > li > ul > li:where([dir="ltr"], [dir="ltr"] *),
  .entry-content ul > li:where([dir="ltr"], [dir="ltr"] *) {
    padding-left:
      1.5rem;
}

.entry-content ol > li > ul > li:where([dir="rtl"], [dir="rtl"] *),
  .entry-content ul > li:where([dir="rtl"], [dir="rtl"] *) {
    padding-right:
      1.5rem;
}

.entry-content img.size-full {
    width: 100%;
  }

.entry-content ol > li > ul > li:before,
  .entry-content ul > li:before {
    --tw-bg-opacity:
      1;
    background-color:
      rgb(1 71 163 / var(--tw-bg-opacity));
}

.entry-content ol > li > ul > li:where([dir="ltr"], [dir="ltr"] *):before,
  .entry-content ul > li:where([dir="ltr"], [dir="ltr"] *):before {
    left:
      5px !important;
    right:
      auto !important;
}

.entry-content ol > li > ul > li:where([dir="rtl"], [dir="rtl"] *):before,
  .entry-content ul > li:where([dir="rtl"], [dir="rtl"] *):before {
    right:
      5px;
    left:
      auto;
}

.entry-content ol > li > ul > li:before,
  .entry-content ul > li:before {
    content: '';
    position: absolute;
    top: 22px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
  }

.entry-content p + ol,
  .entry-content p + ul {
    margin-top: -12px;
  }

.entry-content hr {
    outline: none;
    border: none;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.04);
    display: block;
    margin: 1.5rem 0;
  }

.entry-content ol > li > ul > li:last-child,
  .entry-content ul > li:last-child {
    border-bottom: none;
  }

.entry-content a.-tag {
    height:
      100% !important;
    white-space:
      normal !important;
    border-radius:
      9999px;
    --tw-bg-opacity:
      1;
    background-color:
      rgb(231 237 255 / var(--tw-bg-opacity));
    padding-top:
      0.375rem;
    padding-bottom:
      0.375rem;
    padding-left:
      1.25rem;
    padding-right:
      1.25rem;
    font-size:
      0.875rem;
    line-height:
      1.25rem;
    font-weight:
      700 !important;
    --tw-text-opacity:
      1 !important;
    color:
      rgb(1 71 163 / var(--tw-text-opacity)) !important;
}

.entry-content a.-tag:hover {
    background-color:
      rgb(1 71 163 / 0.2) !important;
}

@media (min-width: 768px) {
    .entry-content a.-tag {
        padding-top:
      0.375rem;
        padding-bottom:
      0.375rem;
    }
}

.entry-content a {
    --tw-text-opacity:
      1;
    color:
      rgb(0 45 115 / var(--tw-text-opacity));
    transition: 0.3s;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    cursor: pointer;
}

/* hightlight */

.entry-content .-highlight {
    background-image: linear-gradient(
      transparent calc(100% - 10px),
      #e7edff 10px
    );
    display:
      block;
    height:
      2rem;
    width:
      -moz-fit-content;
    width:
      fit-content;
    text-transform:
      capitalize;
    --tw-text-opacity:
      1;
    color:
      rgb(0 45 115 / var(--tw-text-opacity));
  }

.entry-content h4.-highlight {
    font-size: 1.5rem !important;
    margin-bottom: 4px !important;
  }

/* input */

.entry-content input {
    width:
      100%;
    border-radius:
      0.25rem;
    border-width:
      1px;
    --tw-border-opacity:
      1;
    border-color:
      rgb(139 170 255 / var(--tw-border-opacity));
    padding-left:
      0.5rem;
    padding-right:
      0.5rem;
    padding-top:
      0.25rem;
    padding-bottom:
      0.25rem;
}

.entry-content input:disabled {
    color: #292929 !important;
    border-color: transparent !important;
    background-color: rgba(0, 0, 0, 0.025) !important;
    -webkit-text-fill-color: #292929 !important;
    opacity: 1 !important;
  }

.entry-content input[type='button'],
  .entry-content input[type='reset'],
  .entry-content input[type='submit'] {
    cursor:
      pointer;
    --tw-border-opacity:
      1;
    border-color:
      rgb(0 45 115 / var(--tw-border-opacity));
    --tw-bg-opacity:
      1 !important;
    background-color:
      rgb(0 45 115 / var(--tw-bg-opacity)) !important;
    --tw-text-opacity:
      1;
    color:
      rgb(255 255 255 / var(--tw-text-opacity));
}

.entry-content .button {
    display:
      inline-flex;
    border-width:
      1px;
    --tw-border-opacity:
      1;
    border-color:
      rgb(139 170 255 / var(--tw-border-opacity));
    --tw-bg-opacity:
      1;
    background-color:
      rgb(1 71 163 / var(--tw-bg-opacity));
    vertical-align:
      top;
    padding: 20px;
    margin: 3px 1px;
    font-weight: 700 !important;
    outline: none !important;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    white-space: normal;
    overflow: hidden;
    height: auto;
    min-width: 140px;
    transition: 0.2s var(--bezier);
    color: white;
}

.entry-content .button::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        -34.83deg,
        rgba(216, 231, 255, 0.2) 17.64%,
        rgba(168, 202, 255, 0.05) 71.04%
      );
      top: 0;
      left: -15%;
      width: 20%;
      height: 100%;
      transform: skew(-45deg);
      transition: all 0.3s var(--bezier);
    }

.entry-content .button::after {
      position: absolute;
      content: '';
      background: linear-gradient(
        34.83deg,
        rgba(216, 231, 255, 0.12) 17.64%,
        rgba(168, 202, 255, 0.05) 71.04%
      );
      top: 0;
      right: -15%;
      width: 20%;
      height: 100%;
      transform: skew(-45deg);
      transition: all 0.3s var(--bezier);
    }

.entry-content .button:not(.-disabled):hover {
    --tw-bg-opacity:
      1;
    background-color:
      rgb(0 45 115 / var(--tw-bg-opacity));
      transform: scale(1.01);
      border-color: transparent;
}

.entry-content .button:not(.-disabled):hover::before {
        left: -50%;
        top: -50%;
      }

.entry-content .button:not(.-disabled):hover::after {
        right: -50%;
        top: 50%;
      }

@media (min-width: 1080px) {

.entry-content .button {
      min-width: 180px
  }
    }

@media (max-width: 1080px) {

.entry-content .button {
      max-width: 100%;
      min-width: 25vw;
      padding: 14px 12px;
      font-size: 0.95rem
  }
    }

@media (max-width: 320px) {

.entry-content .button {
      max-width: 100%;
      min-width: 40vw;
      padding: 14px 18px
  }
    }

.summary-card {
    border-radius: 1rem;
    background-image: url('https://static.bangkokhospital.com/uploads/2024/06/blue-bg-card.png');
    background-size: cover;
    background-position: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    --tw-shadow: 0px -8px 20px 0px rgba(121, 153, 201, 0.10), 0px 8px 10px 0px rgba(159, 150, 128, 0.10);
    --tw-shadow-colored: 0px -8px 20px 0px var(--tw-shadow-color), 0px 8px 10px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
@media (min-width: 768px) {
    .summary-card {
        padding: 1.25rem !important
    }
}

