.swiper-container {
  height: 100%;
  /* max-height: 100vw; */
  min-height: 0;
  min-width: 0;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  display: block;
  height: auto !important;
  max-height: 100%;
}

.swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;

  /* padding for pagination */
}

/* .swiper-pagination-bullets {
  @apply !tw-bottom-2.5;
} */

.swiper-pagination-bullet {
  border-radius: 5px !important;
  width: 5px !important;
  height: 5px !important;
  @apply !tw-bg-black tw-duration-300;
}
.swiper-pagination-bullet-active {
  width: 24px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  @apply tw-text-black tw-duration-300;
  font-size: 28px !important;
  display: flex;
  justify-content: center;
}

.swiper-button-next:after {
  left: 2px;
  @apply tw-relative;
}

.swiper-button-prev:after {
  right: 2px;
  @apply tw-relative;
}

.swiper-button-disabled {
  @apply !tw-pointer-events-auto !tw-cursor-not-allowed;
}

.swiper-button-disabled:after {
  @apply !tw-text-black/20;
}

.swiper-button-next,
.swiper-button-prev {
  @apply !tw-opacity-0 tw-transition tw-bg-white !tw-w-12 !tw-h-12 tw-shadow-md tw-rounded-full;
  transform: translateY(20px);
  transition: all 0.2s ease-in-out !important;
  top: 45% !important;
  /* move pagination up a little bit for balancing with pagination */
}

/* Some navigation might overlap card title when hover */
/* Ex: HighlightPosts */
.offset-navigation .swiper-button-next,
.offset-navigation .swiper-button-prev {
  top: 20% !important;
}

.hero-slide-with-backdrop .swiper-button-next,
.hero-slide-with-backdrop .swiper-button-prev {
  top: 38% !important;
}

.hero-slide-with-backdrop .swiper-button-prev {
  left: 1.5rem;
}
.hero-slide-with-backdrop .swiper-button-next {
  right: 0.5rem;
}

.backdrop-panel {
  @apply tw-w-full tw-h-full tw-absolute -tw-z-10 tw-duration-500 tw-delay-300 tw-right-0  tw-bottom-0 tw-transition-all;
}

.swiper-slide-active .backdrop-panel {
  @apply tw-right-3  tw-bottom-3 md:tw-right-4 md:tw-bottom-4 tw-rounded;
}

.swiper-button-next,
.swiper-button-prev {
  @apply tw-backdrop-blur-sm tw-backdrop-filter tw-backdrop-contrast-75 tw-backdrop-brightness-75 tw-backdrop-saturate-150 tw-backdrop-opacity-50;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  transform: translateY(0px);
  opacity: 1 !important;
  transition: all 0.2s ease-in-out !important;
}

.swiper-button-next[aria-disabled='true'],
.swiper-button-prev[aria-disabled='true'] {
  @apply tw-text-gray-300;
}

@media (max-width: theme('screens.sm')) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.inverted .swiper-button-next,
.inverted .swiper-button-prev {
  @apply tw-bg-black/5;
}

.inverted .swiper-button-next:after,
.inverted .swiper-button-prev:after {
  @apply tw-text-white/80;
}

.inverted .swiper-button-next[aria-disabled='true'],
.inverted .swiper-button-prev[aria-disabled='true'] {
  @apply !tw-opacity-20;
}

.remove-padding .swiper-wrapper {
  padding: 0px !important;
}

.left-pagination .swiper-pagination {
  text-align: left !important;
}

@media (max-width: 1079px) {
  .center-pagination-mobile .swiper-pagination {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: theme('screens.sm')) {
  .center-pagination-desktop .swiper-pagination {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
}

.pagination-x-padding .swiper-pagination {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* has side effect on other page. Don't force it for every page */
/* .swiper-pagination {
  @apply md:!tw-bottom-2.5 !-tw-bottom-2.5;
} */

@media (max-width: 1079px) {
  .overflow-visible-mobile {
    overflow: visible !important;
  }
}

/* Add  watchSlidesProgress: true to swiper options when using swiper to hide overflow slide */
.watch-slide .swiper-slide {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s !important;
}
.watch-slide .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s !important;
}

.has-divider {
  @apply tw-relative;
  &::after {
    @apply tw-h-full tw-w-[2px] tw-bg-gray-200 tw-absolute tw-top-0 tw-bottom-0 tw--left-[19px];
    content: '';
  }
}
